<template>
  <div>
    <section>
      <div class="row header">
        <div
          @click="cardPaymentMethod = true"
          :class="{
            selected: cardPaymentMethod,
            column: true,
            w50: !disableDirectDebit,
            'with-border': true,
            disabled: isDirectDebit && inPayment,
          }"
        >
          <div>
            <font-awesome-icon
              class="brand-icon"
              :icon="['far', 'credit-card']"
            />
            <span>{{ $t("generic_field_forms.credit_card") }}</span>
          </div>
        </div>
        <div
          @click="cardPaymentMethod = false"
          :class="{
            selected: !cardPaymentMethod,
            column: true,
            w50: true,
            'with-border': true,
            disabled: isCard && inPayment,
          }"
          v-if="!disableDirectDebit"
        >
          <div>
            <img class="brand-icon" src="@/assets/direct_debit.svg" />
            <span>{{ $t("generic_field_forms.direct_debit") }}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="column">
          <div class="payment-method">
            <b2b-card
              v-if="cardPaymentMethod === true"
              @paymentMethod="cardListener"
              @cardError="cardErrorListener"
              :paymentInstance="paymentInstance"
              @hook:mounted="
                isCard = true;
                isDirectDebit = false;
              "
            ></b2b-card>
            <b2b-direct-debit-adyen
              v-else
              @paymentMethod="directDebitListner"
              :hasEmail="hasEmail"
              :paymentInstance="paymentInstance"
              @hook:mounted="
                isCard = false;
                isDirectDebit = true;
              "
            >
            </b2b-direct-debit-adyen>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import B2bCard from "./B2bCard.vue";
import B2bDirectDebitAdyen from "./B2bDirectDebitAdyen.vue";

export default {
  name: "B2bPaymentMethod",
  components: {
    "b2b-card": B2bCard,
    "b2b-direct-debit-adyen": B2bDirectDebitAdyen,
  },
  props: {
    hasEmail: {
      type: Boolean,
      default: false,
    },
    paymentInstance: {
      type: Object,
      default: null,
    },
    inPayment: {
      type: Boolean,
      default: false,
    },
    disableDirectDebit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cardPaymentMethod: true,
      isCard: false,
      isDirectDebit: false,
    };
  },
  methods: {
    /**
     * Propagate component content to the parent component
     */
    cardErrorListener(error) {
      this.$emit("cardError", {
        error,
      });
    },
    /**
     * Propagate component content to the parent component
     */
    cardListener(metadata) {
      this.$emit("paymentMethod", {
        metadata,
        element: "card",
      });
    },
    /**
     * Propagate component content to the parent component
     */
    directDebitListner(metadata) {
      this.$emit("paymentMethod", {
        metadata,
        element: "directDebit",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: none;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

svg {
  color: black;
}

.brand-icon {
  margin-right: 0.25rem;
}

.with-border {
  border-bottom: 2px solid #dddddd;
  font-size: 0.81rem;
  font-weight: 500;
  cursor: pointer;
  text-align: center;

  img {
    height: 1em;
    vertical-align: -0.125rem;
    line-height: 1;
    display: inline-block;
  }

  &.selected {
    border-bottom-width: 2px;
    border-bottom-color: #4e6cff;
    color: #4e6cff;
    font-weight: 600;
  }
}

.column {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 1.5rem;
  width: 100%;

  &.w50 {
    width: 50%;
  }

  &.padding {
    padding: 0.5rem 0;
  }
}
.payment-method {
  h2 {
    margin: 15px 0;
    color: #32325d;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    font-size: 13px;
    font-weight: 500;
  }
}

.check-conditions {
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 1rem;
  font-size: 0.81rem;
  font-weight: 500;

  input {
    cursor: pointer;
    height: 1rem;
    width: 1.2rem;
    min-width: 1.2rem;
    border-radius: 0.2rem;
    margin-right: 0.3rem;
  }

  a {
    font-size: 0.81rem;
    color: #4e6cff;
    font-weight: 600;
    cursor: pointer;
  }
}

button {
  display: block;
  color: #fff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 0;
  font-weight: 700;
  width: 100%;
  height: 40px;
  outline: none;
  cursor: pointer;
  transition: all 0.15s ease;
}

button:focus {
  background: #555abf;
}

button:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.08);
}

button:active {
  background: #43458b;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.2;
}

button.payment {
  height: 46px;
  background: #4760ff;
}
</style>
