<template>
  <div class="mainbox">
    <b2b-loading-animation bgColor="#4760ff"></b2b-loading-animation>
  </div>
</template>

<script>
import B2bLoadingAnimation from "./B2bLoadingAnimation.vue";

export default {
  name: "B2bLoadingPage",
  components: {
    "b2b-loading-animation": B2bLoadingAnimation,
  },
};
</script>
<style lang="scss" scoped>
@import "@/app";
* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  font-size: 15px;
  line-height: 1.4em;
}

.mainbox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 0.1rem);
  margin: 0 auto;
  padding: 0 1rem;
  text-align: center;
  background-color: #e7edfa73;
}
</style>
