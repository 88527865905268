<template>
  <div class="modal-detail">
    <b2b-modal
      :title="$t('contract_conditions.title')"
      @closeModal="$emit('clicked')"
    >
      <div class="justify">
        <span class="condition">
          {{ $t("contract_conditions.first_clause") }}
        </span>
        {{ $t("contract_conditions.first_text") }}
      </div>

      <div class="justify">
        <span class="condition">
          {{ $t("contract_conditions.second_clause") }}
        </span>
        {{ $t("contract_conditions.second_text") }}
      </div>

      <div class="justify">
        <span class="condition">
          {{ $t("contract_conditions.third_clause") }}
        </span>
        {{ $t("contract_conditions.third_text") }}
      </div>

      <div class="justify">
        <span class="condition">
          {{ $t("contract_conditions.fourth_clause") }}
        </span>
        {{ $t("contract_conditions.fourth_text_pre") }}
      </div>

      <div class="justify">
        <span class="condition">
          {{ $t("contract_conditions.fourth_one.number") }}
        </span>
        {{ $t("contract_conditions.fourth_one.text") }}
      </div>

      <div class="justify">
        {{ $t("contract_conditions.fourth_text_post") }}
      </div>

      <div class="justify" v-if="$t('contract_conditions.supplements')">
        <span class="condition">
          {{ $t("contract_conditions.supplements") }}
        </span>
        <li>{{ $t("contract_conditions.1_sup") }}</li>
        <li>{{ $t("contract_conditions.2_sup") }}</li>
        <li>{{ $t("contract_conditions.3_sup") }}</li>
        <li>{{ $t("contract_conditions.4_sup") }}</li>
        <li>{{ $t("contract_conditions.5_sup") }}</li>
        <li>{{ $t("contract_conditions.6_sup") }}</li>
        <li>{{ $t("contract_conditions.7_sup") }}</li>
        <li v-if="$t('contract_conditions.8_sup')">
          {{ $t("contract_conditions.8_sup") }}
        </li>
        <li v-if="$t('contract_conditions.9_sup')">
          {{ $t("contract_conditions.9_sup") }}
        </li>
        <div class="space">{{ $t("contract_conditions.fourth_text2") }}</div>
      </div>

      <div class="justify">
        <span class="condition">
          {{ $t("contract_conditions.fifth_clause") }}
        </span>
        {{ $t("contract_conditions.fifth_text") }}
        <div class="space-small">
          <li>{{ $t("contract_conditions.fifth_clause_1") }}</li>
          <li>{{ $t("contract_conditions.fifth_clause_2") }}</li>
          <li>{{ $t("contract_conditions.fifth_clause_3") }}</li>
        </div>
        <span class="condition row surround">
          <a :href="$t('contract_conditions.link_price')" target="_blank">
            <u>{{ $t("contract_conditions.link_price_name") }}</u>
          </a>
        </span>
        <span>
          {{ $t("contract_conditions.fifth_text2") }}
        </span>
      </div>

      <div class="justify">
        <span class="condition">
          {{ $t("contract_conditions.sixth_clause") }}
        </span>
        {{ $t("contract_conditions.sixth_text") }}
        <span class="bold">{{
          $t("contract_conditions.sixth_text_duration")
        }}</span>
        {{ $t("contract_conditions.sixth_text_2") }}
        <span class="bold">{{
          $t("contract_conditions.sixth_text_deadline")
        }}</span>
        {{ $t("contract_conditions.sixth_text_3") }}
      </div>

      <div class="justify">
        <span class="condition">
          {{ $t("contract_conditions.seventh_clause") }}
        </span>
        {{ $t("contract_conditions.seventh_text") }}
      </div>

      <div class="justify">
        <span class="condition">
          {{ $t("contract_conditions.octave_clause") }}
        </span>
        {{ $t("contract_conditions.octave_text") }}
      </div>

      <div class="justify">
        <span class="condition">
          {{ $t("contract_conditions.ninth_clause") }}
        </span>
        {{ $t("contract_conditions.ninth_text") }}
      </div>

      <div class="justify">
        <span class="condition">
          {{ $t("contract_conditions.tenth_clause") }}
        </span>
        {{ $t("contract_conditions.tenth_text") }}
      </div>

      <div class="justify">
        <span class="condition">
          {{ $t("contract_conditions.eleventh_clause") }}
        </span>
        {{ $t("contract_conditions.eleventh_text") }}
      </div>

      <div class="justify">
        <span class="condition">
          {{ $t("contract_conditions.twelfth_clause") }}
        </span>
        <div class="space-small">
          {{ $t("contract_conditions.twelfth_text") }}
        </div>
        <div class="space-small">
          {{ $t("contract_conditions.twelfth_text_2") }}
        </div>
        <div class="space-small">
          {{ $t("contract_conditions.twelfth_text_3") }}
        </div>
      </div>

      <div class="justify" v-if="$t('contract_conditions.twelfth_claim_1')">
        <span> {{ $t("contract_conditions.twelfth_claim_1") }} </span><br />
        <span> {{ $t("contract_conditions.twelfth_claim_2") }} </span><br />
        <span> {{ $t("contract_conditions.twelfth_claim_3") }} </span><br />
        <span> {{ $t("contract_conditions.twelfth_claim_4") }} </span><br />
        <span> {{ $t("contract_conditions.twelfth_claim_5") }} </span><br />
        <span> {{ $t("contract_conditions.twelfth_claim_6") }} </span><br />
      </div>

      <div class="justify">
        <span class="condition">
          {{ $t("contract_conditions.thirteenth_clause") }}
        </span>
        {{ $t("contract_conditions.thirteenth_text") }}
      </div>
    </b2b-modal>
  </div>
</template>

<script>
import B2bModal from "@/components/B2bModal/B2bModal.vue";

export default {
  name: "PaymentLinkContract",
  components: {
    "b2b-modal": B2bModal,
  },
  props: {
    showModal: Boolean,
  },
  methods: {
    closeModal() {
      this.$emit("clicked");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-detail {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba($color: #ffffff, $alpha: 0.6);
  z-index: 1;
}

/deep/ .modal {
  min-width: 75%;
  min-height: 75%;
  text-align: justify;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 900;
  width: 100%;
  max-width: 400px;
  max-height: 400px;
  background-color: #fff;
  border-radius: 8px;
  padding: 25px;
}
</style>
