<template>
  <div id="component-container" ref="card"></div>
</template>

<script>
import { mapState } from "vuex";
import api from "@/api";

export default {
  name: "B2bCard",
  props: [
    "billingData",
    "email",
    "integrityKey",
    "shouldDisplayCard",
    "paymentInstance",
  ],
  data() {
    return {
      adyenCard: null,
      adyenCheckout: null,
      cbInstance: null,
    };
  },
  computed: {
    ...mapState({
      siteLanguage: (state) => state.languages.siteLanguage,
    }),
  },
  methods: {
    /**
     * Helper method to display validation errors preventing payment flow continue normally
     */
    runValidation() {
      // Check if adyen card state is valid
      if (this.adyenCard && !this.adyenCard.state.isValid) {
        this.adyenCard.showValidation();
        return null;
      }

      return true;
    },
    /**
     * Helper method to make handleCardPayment promise compliant
     */
    handleCardPayment(threeDSHandler, paymentInfo, integrityKey) {
      return new Promise((resolve, reject) => {
        threeDSHandler.handleCardPayment(paymentInfo, {
          success: async (intent) => {
            // Triggers when card is 3DS authorized
            try {
              await api.payments.pay({
                payment_intent_id: intent.id,
                key: integrityKey,
                email: null,
                direct_debit_iban: null,
              });
              resolve();
            } catch (error) {
              window.logger.error("ERROR ", error);
              reject(error);
              throw new Error(`Error performing pay: ${error}`);
            }
          },
          error: async (intent, error) => {
            try {
              await api.payments.releaseTransaction({
                key: integrityKey,
              });
            } catch (error) {
              // reject(error);
              // throw new Error(`Error releasing transaction: ${error}`);
            }
            reject(error);
          },
        });
      });
    },
    async run(email, integrityKey, billingData) {
      // Create or update the customer first
      await api.payments.customer({
        first_name: billingData.name,
        last_name: billingData.lastName,
        address: billingData.address,
        city: billingData.city,
        state: billingData.state,
        zip: billingData.postalCode,
        country: billingData.country,
        vat_number: billingData.vat,
        phone: billingData.phone,
        key: integrityKey,
        is_customer: billingData.isCustomer,
      });

      const response = await api.payments.paymentIntent({
        key: integrityKey,
      });

      const threeDSHandler = await this.paymentInstance
        .getChargebeeInstance()
        .load3DSHandler();

      threeDSHandler.setPaymentIntent(response.data.body.payment_intent, {
        adyen: this.adyenCheckout,
      });

      const paymentInfo = {
        element: this.adyenCard,
      };

      // Handle payment flow only if is mandatory to display this component (i.e. business accounts)
      await this.handleCardPayment(threeDSHandler, paymentInfo, integrityKey);
    },
  },
  async mounted() {
    this.adyenCheckout = await this.paymentInstance.initAdyen({
      onChange: this.handleOnChange,
    });
    this.adyenCard = await this.adyenCheckout
      .create("card")
      .mount("#component-container");

    // Emit object to parent
    this.$emit("paymentMethod", {
      runValidation: this.runValidation,
      run: this.run,
    });
  },
};
</script>
