<template>
  <div>
    <b2b-loading-animation
      v-if="loading"
      :height="height"
      :width="width"
      :bgColor="bgColor"
    ></b2b-loading-animation>
    <div class="column" v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import B2bLoadingAnimation from "./B2bLoadingAnimation.vue";

export default {
  name: "B2bLoading",
  components: {
    "b2b-loading-animation": B2bLoadingAnimation,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: "20px",
    },
    width: {
      type: String,
      default: "20px",
    },
    bgColor: {
      type: String,
      default: "#fff",
    },
  },
};
</script>
