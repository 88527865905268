<template>
  <div id="sepa-container" ref="sepadirectdebit"></div>
</template>

<script>
import { mapState } from "vuex";
import api from "@/api";

export default {
  name: "B2bDirectDebitAdyen",
  props: [
    "billingData",
    "email",
    "integrityKey",
    "shouldDisplayCard",
    "paymentInstance",
  ],
  data() {
    return {
      adyenDirectDebit: null,
      adyenCheckout: null,
      cbInstance: null,
    };
  },
  computed: {
    ...mapState({
      siteLanguage: (state) => state.languages.siteLanguage,
    }),
  },
  methods: {
    /**
     * Helper method to display validation errors preventing payment flow continue normally
     */
    runValidation() {
      // Check if adyen direct debit state is valid
      if (this.adyenDirectDebit && !this.adyenDirectDebit.state.isValid) {
        this.adyenDirectDebit.showValidation();
        return null;
      }

      return true;
    },
    async run(email, integrityKey, billingData) {
      const paymentMethodData = this.adyenDirectDebit.data.paymentMethod;

      // Create or update the customer first
      await api.payments.customer({
        first_name: billingData.name,
        last_name: billingData.lastName,
        address: billingData.address,
        city: billingData.city,
        state: billingData.state,
        zip: billingData.postalCode,
        country: billingData.country,
        vat_number: billingData.vat,
        phone: billingData.phone,
        key: integrityKey,
        is_customer: billingData.isCustomer,
      });

      // Make the payment
      await api.payments.pay({
        payment_intent_id: null,
        key: integrityKey,
        direct_debit_iban: paymentMethodData.iban,
        email: this.email || email,
      });
    },
  },
  async mounted() {
    this.adyenCheckout = await this.paymentInstance.initAdyen({
      onChange: this.handleOnChange,
    });
    this.adyenDirectDebit = await this.adyenCheckout
      .create("sepadirectdebit")
      .mount("#sepa-container");

    // Emit object to parent
    this.$emit("paymentMethod", {
      runValidation: this.runValidation,
      run: this.run,
    });
  },
};
</script>
