<template>
  <section>
    <div class="modal column">
      <div class="header row">
        <div class="column full-width">
          <h1>
            {{ title }}
          </h1>
        </div>
        <div class="column right">
          <font-awesome-icon
            class="close-icon"
            icon="times"
            size="lg"
            @click.prevent="closeModal"
          />
        </div>
      </div>
      <div class="body row">
        <div class="column full-width">
          <slot></slot>
        </div>
      </div>
    </div>
    <div class="background"></div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/app";
* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  font-size: 15px;
  line-height: 1.4em;
  color: #525f7f;
}

section {
  position: fixed;
}

.header {
  margin-bottom: 1.5rem;
}

.body {
  overflow-y: auto;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
}

.full-width {
  width: 100%;
}

.right {
  margin-left: auto;
}

.modal {
  padding: 2rem;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
    0px 2px 4px rgba(96, 97, 112, 0.16);
  background: #fff;
  z-index: 900;
  border-radius: 7px;

  h1 {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: left;
  }

  .close-icon {
    width: 1.4rem;
    height: 1.4rem;
    cursor: pointer;
  }

  .condition {
    font-weight: 600;
  }

  .justify {
    text-align: justify;
    margin-bottom: 1rem;
    margin-right: 0.5rem;
  }

  li {
    padding: 0 1.5rem;
  }

  .space {
    margin-top: 1rem;
  }

  .surround {
    padding: 0.45rem 0;
  }
}

.background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 600;
  overflow: hidden;
  background: rgba(20, 40, 75, 0.4);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  will-change: opacity;
}
</style>
