<template>
  <section>
    <div class="row">
      <div class="column full-width">
        <div class="row row-address">
          <span><font-awesome-icon size="lg" icon="map-marker-alt" /></span>
          <div class="address">
            <span v-if="email">{{ email }} </span>
            <div class="capitalized header">{{ name }} {{ lastName }}</div>
            <div v-if="vat">{{ vat }}</div>
            <div v-if="phone">{{ phone }}</div>
            <div class="upper">{{ address }}</div>
            <div class="capitalized">
              {{ postalCode }}, {{ city }} ({{ state }})
            </div>
            <div>{{ country }}</div>
          </div>
        </div>
      </div>
      <div class="column right">
        <a @click.prevent="edit()">Editar</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "B2bBillingData",
  props: {
    email: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    lastName: {
      type: String,
      default: null,
    },
    vat: {
      type: String,
      default: null,
    },
    address: {
      type: String,
      default: null,
    },
    postalCode: {
      type: String,
      default: null,
    },
    city: {
      type: String,
      default: null,
    },
    state: {
      type: String,
      default: null,
    },
    country: {
      type: String,
      default: null,
    },
    phone: {
      type: String,
      default: null,
    },
  },
  methods: {
    edit() {
      this.$emit("edit-billing-data", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  & > span {
    margin-right: 0.5rem;
    width: 1.2rem;
    margin-left: 0.5rem;
  }

  .address {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    .capitalized {
      text-transform: capitalize;
    }

    .upper:first-letter {
      text-transform: uppercase;
    }

    .header {
      font-weight: 600;
    }
  }
}
.column {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 0.3rem;
  margin-top: 1.5rem;

  span {
    font-weight: 600;
  }
}
.full-width {
  width: 100%;
}

.right {
  margin-left: auto;
  margin-bottom: auto;
}

.row-address {
  align-items: flex-start;
  height: auto;
  border-left: 1px solid #d1d1d1;
}

a {
  color: #4760ff;
  font-weight: 500;
  font-size: 0.83rem;
  border: 1px solid;
  border-radius: 5px;
  padding: 0.3rem 0.6rem;
}

a:hover {
  color: #fff;
  background-color: #4760ff;
  cursor: pointer;
}
</style>
