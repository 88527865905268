<template>
  <transition name="fade" appear>
    <div class="confirm">
      <font-awesome-icon
        class="post-icon bounce red"
        size="10x"
        :icon="['fas', 'times']"
        v-if="cautionIcon"
      />
      <font-awesome-icon
        class="post-icon"
        size="10x"
        :icon="['fa', 'check']"
        v-if="!cautionIcon && !isPaid"
      />
      <font-awesome-icon
        class="post-icon"
        size="10x"
        :icon="['far', 'check-circle']"
        v-if="!cautionIcon && isPaid"
      />
      <div class="text">
        {{ outputMessage }}
        <span v-if="isPaid"> - {{ amount | formatCurrency("EUR") }}</span>
      </div>
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: "B2bPaymentFinishPage",
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    message: {
      type: String,
      default: null,
    },
    cautionIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isPaid() {
      return this.amount > 0 && !this.message;
    },
    outputMessage() {
      return this.message || this.$t("checkout.finish-page-message");
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm {
  background-color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  div {
    padding: 2rem 2rem;
    font-weight: 600;
    color: #454d54;
    font-size: 2rem;
    line-height: 2rem;
  }

  .post-icon {
    color: #4760ff;
  }

  .red {
    color: #e55e5e;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.bounce {
  display: inline-block;
  position: relative;
  -moz-animation: bounce 1.2s infinite linear;
  -o-animation: bounce 1.2s infinite linear;
  -webkit-animation: bounce 1.2s infinite linear;
  animation: bounce 1.2s infinite linear;
}

@-webkit-keyframes bounce {
  0% {
    top: 0;
  }
  25% {
    top: -0.05em;
  }
  50% {
    top: -0.1em;
  }
  75% {
    top: -0.15em;
  }
  100% {
    top: 0;
  }
}
@-moz-keyframes bounce {
  0% {
    top: 0;
  }
  25% {
    top: -0.05em;
  }
  50% {
    top: -0.1em;
  }
  75% {
    top: -0.15em;
  }
  100% {
    top: 0;
  }
}
@-o-keyframes bounce {
  0% {
    top: 0;
  }
  25% {
    top: -0.05em;
  }
  50% {
    top: -0.1em;
  }
  75% {
    top: -0.15em;
  }
  100% {
    top: 0;
  }
}
@-ms-keyframes bounce {
  0% {
    top: 0;
  }
  25% {
    top: -0.05em;
  }
  50% {
    top: -0.1em;
  }
  75% {
    top: -0.15em;
  }
  100% {
    top: 0;
  }
}
@keyframes bounce {
  0% {
    top: 0;
  }
  25% {
    top: -0.05em;
  }
  50% {
    top: -0.1em;
  }
  75% {
    top: -0.15em;
  }
  100% {
    top: 0;
  }
}
</style>
